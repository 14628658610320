document.addEventListener('turbolinks:load', () => {
  const navTabLinks = document.querySelectorAll('a.nav-tab-link');
  const navTabContents = document.querySelectorAll('div.nav-tab-content');

  const deactivateNavTabs = () => {
    navTabLinks.forEach((navTab) => {
      navTab.classList.remove('active');
    });
  };

  const hideNavTabContents = () => {
    navTabContents.forEach((navContent) => {
      navContent.classList.add('hidden');
    });
  }

  navTabLinks.forEach((navTab) => {
    navTab.addEventListener('click', (e) => {
      e.preventDefault();

      deactivateNavTabs();
      navTab.classList.add('active');

      hideNavTabContents();
      let navContent = document.querySelector(`div.nav-tab-content#${navTab.id}`);
      navContent.classList.remove('hidden');
      navContent.classList.add('block');
    });
  });

  const exampleSentencesLink = document.getElementById('examples-link');

  if (exampleSentencesLink) {
    exampleSentencesLink.addEventListener('click', (e) => {
      e.preventDefault();
      document.getElementById('examples').click();
    });
  }
});