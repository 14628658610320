document.addEventListener('turbolinks:load', () => {
  const searchBarForm = document.getElementById('search-bar-form');

  if (searchBarForm) {
    const baseUrl = searchBarForm.dataset.baseUrl;

    searchBarForm.addEventListener('submit', (e) => {
      e.preventDefault();

      let searchTerm = document.getElementById('search-bar-form-term').value;
      window.location.href = baseUrl + 'translate/' + searchTerm;
    });
  }
});
