document.addEventListener('turbolinks:load', () => {
  const examplesLink = document.getElementById('examples-link');

  if (examplesLink) {
    examplesLink.addEventListener('click', (e) => {
      e.preventDefault();

      let examplesTab = document.getElementById('examples-tab');
      examplesTab.click();
    });
  }

  const navLinks = document.getElementsByClassName('nav-link');

  if (navLinks) {
    for (i = 0; i < navLinks.length; i++) {
      navLinks[i].addEventListener('click', (e) => {
        let target = e.target || e.srcElement;

        hideableDiv = document.getElementById('hideable');

        return (hideableDiv === undefined || hideableDiv === null);

        if (target.id !== 'dictionary-tab') {
          hideableDiv.classList.add('d-none');
        } else {
          hideableDiv.classList.remove('d-none');
        }
      });
    }
  }
});