document.addEventListener('turbolinks:load', () => {
  const modalBackground = document.getElementsByClassName('modal-background')[0];
  const modals = document.getElementsByClassName('modal');
  const modalCloseButtons = document.getElementsByClassName('modal-close');

  const hideAllModals = () => {
    modalBackground.classList.remove('flex');
    modalBackground.classList.add('hidden');

    Array.from(modals).forEach((modal) => {
      modal.classList.remove('flex');
      modal.classList.add('hidden');
    });
  };

  const showModal = (modalTarget) => {
    modalBackground.classList.remove('hidden');
    modalBackground.classList.add('flex');

    modalTarget.classList.remove('hidden');
    modalTarget.classList.add('flex');
  };

  if (modalBackground && modals.length) {
    const modalLinks = document.querySelectorAll('[data-toggle="modal"]');

    modalBackground.addEventListener('click', (e) => {
      Array.from(modals).forEach((modal) => {
        if (!document.getElementById(modal.id).contains(e.target)) {
          hideAllModals();
        }
      });
    });

    modalLinks.forEach((modalLink) => {
      modalLink.addEventListener('click', (e) => {
        e.preventDefault();

        let modalId = modalLink.dataset.target;
        let modalTarget = document.getElementById(modalId);

        hideAllModals();
        showModal(modalTarget);
      });
    });

    Array.from(modalCloseButtons).forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();

        hideAllModals();
      });
    });
  }
});