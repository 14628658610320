document.addEventListener('turbolinks:load', () => {
  const navButton = document.getElementById('nav_button');
  const navItems = document.getElementById('nav-items');
  let open = false;

  const toggleNavBar = () => {
    open = !open;
    if (open) {
      navItems.classList.remove('hidden');
      navItems.classList.add('block');
    } else {
      navItems.classList.remove('block');
      navItems.classList.add('hidden');
    }
  }

  navButton.addEventListener('click', () => {
    toggleNavBar();
  });
});