document.addEventListener('turbolinks:load', function() {
  if (document.getElementById('admin-words-show')) {
    var wordShowDiv = document.getElementById('admin-words-show');
    var wordId = wordShowDiv.dataset.wordId;
    var definitionsList = document.getElementById('definitions-list');

    var sortable = new Sortable(definitionsList, {
      onUpdate: function(event) {
        var item = event.item;
        var definitionId = item.dataset.definitionId;

        // $.ajax({
        //   url: '/admin/definition_positions/' + definitionId,
        //   method: 'PUT',
        //   dataType: 'json',
        //   beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        //   data: {
        //     definition: {
        //       position: event.newIndex
        //     }
        //   }
        // });
      }
    });
  }

  if (document.getElementById('admin-verb-perfective-pairing')) {
    var verbPerfectiveDiv = document.getElementById('admin-verb-perfective-pairing');
    var wordId = verbPerfectiveDiv.dataset.wordId;
    var getUrl = '/admin/verb_perfective_maps.json' + '?word_id=' + wordId;

    var addVerbPairing = function(pairId) {
      var pairId = pairId;

      // $.ajax({
      //   url: '/admin/verb_perfective_maps.json' + '?word_id=' + wordId,
      //   method: 'POST',
      //   dataType: 'json',
      //   beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      //   data: {
      //     verb_perfective_map: {
      //       pair_id: pairId
      //     }
      //   },
      //   success: function() {
      //     window.location.reload();
      //   },
      //   error: function() {
      //     window.location.reload();
      //   }
      // });
    };

    // $.get(getUrl, function(data) {
    //   var options = {
    //     data: data,
    //     getValue: 'name',

    //     list: {
    //       maxNumberOfElements: 10,
    //       match: {
    //         enabled: true
    //       },
    //       onClickEvent: function() {
    //         var id = $("#verb-pairing-input").getSelectedItemData().id;
    //         addVerbPairing(id);
    //       }
    //     }
    //   };

    //   $('#verb-pairing-input').easyAutocomplete(options);
    // });
  }

  if (document.getElementById('admin-verbal-adverb-perfective-pairing')) {
    var verbPerfectiveDiv = document.getElementById('admin-verbal-adverb-perfective-pairing');
    var wordId = verbPerfectiveDiv.dataset.wordId;
    var getUrl = '/admin/verbal_adverb_perfective_maps.json' + '?word_id=' + wordId;

    var addVerbPairing = function(pairId) {
      var pairId = pairId;

      // $.ajax({
      //   url: '/admin/verbal_adverb_perfective_maps.json' + '?word_id=' + wordId,
      //   method: 'POST',
      //   dataType: 'json',
      //   beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      //   data: {
      //     verbal_adverb_perfective_map: {
      //       pair_id: pairId
      //     }
      //   },
      //   success: function() {
      //     window.location.reload();
      //   },
      //   error: function() {
      //     window.location.reload();
      //   }
      // });
    };

    // $.get(getUrl, function(data) {
    //   var options = {
    //     data: data,
    //     getValue: 'name',

    //     list: {
    //       maxNumberOfElements: 10,
    //       match: {
    //         enabled: true
    //       },
    //       onClickEvent: function() {
    //         var id = $("#verbal-adverb-pairing-input").getSelectedItemData().id;
    //         addVerbPairing(id);
    //       }
    //     }
    //   };

    //   $('#verbal-adverb-pairing-input').easyAutocomplete(options);
    // });
  }

  if (document.getElementById('admin-verbal-adverb-to-verb-perfective-pairing')) {
    var verbalAdverbDiv = document.getElementById('admin-verbal-adverb-to-verb-perfective-pairing');
    var verbalAdverbId = verbalAdverbDiv.dataset.verbalAdverbId;
    var getUrl = '/admin/verbal_adverb_pairings.json' + '?id=' + verbalAdverbId;

    var addVerbPairing = function(wordId) {
      var wordId = wordId;

      // $.ajax({
      //   url: '/admin/verbal_adverb_pairings/' + verbalAdverbId + '.json',
      //   method: 'PUT',
      //   dataType: 'json',
      //   beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      //   data: {
      //     verbal_adverb: {
      //       word_id: wordId
      //     }
      //   },
      //   success: function() {
      //     window.location.reload();
      //   },
      //   error: function() {
      //     window.location.reload();
      //   }
      // });
    };

    // $.get(getUrl, function(data) {
    //   var options = {
    //     data: data,
    //     getValue: 'name',

    //     list: {
    //       maxNumberOfElements: 10,
    //       match: {
    //         enabled: true
    //       },
    //       onClickEvent: function() {
    //         var id = $("#verb-pairing-input").getSelectedItemData().id;
    //         addVerbPairing(id);
    //       }
    //     }
    //   };

    //   $('#verb-pairing-input').easyAutocomplete(options);
    // });
  }

  if (document.getElementById('admin-adverb-to-adjective-pairing')) {
    var adverbDiv = document.getElementById('admin-adverb-to-adjective-pairing');
    var adverbId = adverbDiv.dataset.adverbId;
    var getUrl = '/admin/adverb_adjective_pairings.json' + '?id=' + adverbId;

    var addVerbPairing = function(wordId) {
      var wordId = wordId;

      // $.ajax({
      //   url: '/admin/adverb_adjective_pairings/' + adverbId + '.json',
      //   method: 'PUT',
      //   dataType: 'json',
      //   beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      //   data: {
      //     adverb: {
      //       word_id: wordId
      //     }
      //   },
      //   success: function() {
      //     window.location.reload();
      //   },
      //   error: function() {
      //     window.location.reload();
      //   }
      // });
    };

    // $.get(getUrl, function(data) {
    //   var options = {
    //     data: data,
    //     getValue: 'name',

    //     list: {
    //       maxNumberOfElements: 10,
    //       match: {
    //         enabled: true
    //       },
    //       onClickEvent: function() {
    //         var id = $("#adjective-pairing-input").getSelectedItemData().id;
    //         addVerbPairing(id);
    //       }
    //     }
    //   };

    //   $('#adjective-pairing-input').easyAutocomplete(options);
    // });
  }
});