document.addEventListener('turbolinks:load', () => {
  const axios = require('axios');
  const csrfToken = document.querySelector('meta[name=csrf-token').content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

  const wordEditNav = document.getElementById('word-edit-nav');

  if (wordEditNav) {
    const baseUrl = wordEditNav.dataset.baseUrl;
    const partOfSpeechesSelect = document.getElementById('part_of_speeches');
    partOfSpeechesSelect.addEventListener('change', () => {
      let partOfSpeechId = partOfSpeechesSelect.value;
      let newUrl = baseUrl + '?part_of_speech_id=' + partOfSpeechId;
      window.location = newUrl;
    });

    const definitionsList = document.getElementById('definitions-list');

    if (definitionsList) {
      const wordId = definitionsList.dataset.wordId;

      let sortable = new Sortable(definitionsList, {
        onUpdate: (event) => {
          let item = event.item;
          let definitionId = item.dataset.definitionId;

          axios.put('/word_editor/definition_positions/' + definitionId, {
            definition: {
              position: event.newIndex
            }
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }
});